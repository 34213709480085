import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import containerClasses from "../styles/container.module.css"
import buttons from "../styles/buttons.module.css"
import locationOn from "../assets/icons/location_on.svg"
import youtube from "../assets/icons/youtube.svg"
import shortLogo from "../assets/images/short-logo.svg"
import mail from "../assets/images/mail.svg"
import iconTrendingFlat from "../assets/icons/icon_trending_flat_white.svg"

const LinksPage = () => {
  return (
    <Layout page="Links">
      <SEO
        title="Dr Elizabeth Hawkes's Links | Handpicked Resources and Links"
        description="Explore Dr Elizabeth Hawkes's curated collection of trusted resources and recommended links, offering valuable insights and expert guidance for patients and professionals alike."
      />
      <div className="bg-white">
        <div className="bg-white background-panel__links">
          <div className="absolute-element">
            <img
              loading="lazy"
              className="inline h-12"
              src={shortLogo}
              alt="Right Arrow"
            />
          </div>
        </div>
        <div className="col-span-4 md:col-span-8 lg:col-span-12"></div>
        <div
          className={`${containerClasses.container} py-16 md:py-24 lg:py-24 `}
        >
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-span-6 lg:col-start-4">
            {/* <p className="mt-0 overline">Why choose Dr Hawkes?</p> */}
            <h1 className="mt-2 font-sans text-3xl font-bold tracking-wide text-center uppercase">
              Dr Elizabeth Hawkes{" "}
            </h1>
            <p className="font-serif text-xl italic font-light leading-tight text-center text-black md:mb-4 md:text-center lg:pr-8">
              Award-winning Consultant Oculoplastic Surgeon and Aesthetic
              Practitioner.
            </p>
            <div className="flex items-center justify-center">
              <img alt="Dr Hawkes accolade" className="m-2" src={locationOn} />
              <p className="mb-0 text-base">120 Sloane St, London SW1X 9BW</p>
            </div>
          </div>

          <div className="flex flex-col col-span-4 md:col-span-6 md:col-start-2 lg:justify-center lg:col-span-6 lg:col-start-4">
            <h2 className="font-sans text-lg font-bold text-center">Website</h2>
            <a
              href="https://drelizabethhawkes.com/contact/"
              className={`block mb-4 w-full p-6 h-12 text-center items-center justify-center ${buttons.btnLargeExpressiveGold}`}
            >
              Book Now
              <img
                loading="lazy"
                className="inline ml-2 mr-0 h-15"
                src={iconTrendingFlat}
                alt="Right Arrow"
              />
            </a>
            <a
              href="https://drelizabethhawkes.com/oculoplastic-services/blepharoplasty-surgery/"
              className={`block mb-4 w-full p-6 h-12 text-center items-center justify-center ${buttons.btnLargeExpressive}`}
            >
              Blepharoplasty
              <img
                loading="lazy"
                className="inline ml-2 mr-0 h-15"
                src={iconTrendingFlat}
                alt="Right Arrow"
              />
            </a>
            <a
              href="https://drelizabethhawkes.com/aesthetic-services/dark-circles/"
              className={`block mb-4 w-full h-12 text-center items-center justify-center ${buttons.btnLargeExpressive}`}
            >
              Dark Circles
              <img
                loading="lazy"
                className="inline ml-2 mr-0 h-15"
                src={iconTrendingFlat}
                alt="Right Arrow"
              />
            </a>
            <h2 className="font-sans text-lg font-bold text-center">
              Featured Articles
            </h2>
            <a
              href="https://drelizabethhawkes.com/in-the-press/"
              className={`block mb-4 w-full h-12 text-center items-center justify-center ${buttons.btnLargeExpressive}`}
            >
              Press Page
              <img
                loading="lazy"
                className="inline ml-2 mr-0 h-15"
                src={iconTrendingFlat}
                alt="Right Arrow"
              />
            </a>
            <h2 className="font-sans text-lg font-bold text-center">
              Social Media
            </h2>
            <a
              href="https://www.youtube.com/@DrElizabethHawkes"
              className={`block mb-4  w-full h-12 text-center border-2 items-center justify-center ${buttons.btnLargeExpressive}`}
            >
              YouTube
              <img
                loading="lazy"
                className="inline ml-2 mr-0 -mt-1 h-15"
                src={youtube}
                alt="Right Arrow"
              />
            </a>
            <div className="flex items-start p-6 mt-6 space-x-4 border-4 border-solid border-goldMid">
              <div className="flex-shrink-0">
                <img
                  alt="Dr Hawkes accolade"
                  className="m-1 mt-2 h-8s"
                  src={mail}
                />
              </div>
              <a href="/links/#footer" className="block">
                <div className="text-white">
                  <h3 className="my-0 font-sans text-lg font-bold text-onLightHighEmphasis">
                    Newsletter Sign-up
                  </h3>
                  <p className="mt-0 text-sm text-onLightHighEmphasis">
                    Sign up for exclusive insights, Dr Hawkes&apos;s expert tips
                    and updates.
                  </p>
                  <p className="mt-0 font-mono text-sm font-semibold underline uppercase text-onLightHighEmphasis">
                    Sign up
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LinksPage
